<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.cooperationPartnerContract.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.cooperationPartnerContract.list.title"></app-i18n>
      </h1>

      <app-cooperation-partner-contract-list-toolbar></app-cooperation-partner-contract-list-toolbar>
      <app-cooperation-partner-contract-list-filter></app-cooperation-partner-contract-list-filter>
      <app-cooperation-partner-contract-list-table :isInPojectImplementation="false"></app-cooperation-partner-contract-list-table>
    </div>
  </div>
</template>

<script>
import cooperationPartnerContractListFilter from '@/modules/cooperation-partner-contract/components/cooperation-partner-contract-list-filter.vue';
import cooperationPartnerContractListTable from '@/modules/cooperation-partner-contract/components/cooperation-partner-contract-list-table.vue';
import cooperationPartnerContractListToolbar from '@/modules/cooperation-partner-contract/components/cooperation-partner-contract-list-toolbar.vue';

export default {
  name: 'app-cooperation-partner-contract-list-page',
  
  components: {
    [cooperationPartnerContractListFilter.name]: cooperationPartnerContractListFilter,
    [cooperationPartnerContractListTable.name]: cooperationPartnerContractListTable,
    [cooperationPartnerContractListToolbar.name]: cooperationPartnerContractListToolbar,
  },
};
</script>

<style>
</style>
