<template>
  <div class="app-page-toolbar">
    <router-link
      :to="{ path: '/audit-logs', query: { entityNames: 'cooperationPartnerContract' } }"
      v-if="hasPermissionToAuditLogs"
    >
      <el-button icon="el-icon-fa-history">
        <app-i18n code="auditLog.menu"></app-i18n>
      </el-button>
    </router-link>

  </div>
</template>

<script>
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { mapGetters } from 'vuex';
import { CooperationPartnerContractPermissions } from '@/modules/cooperation-partner-contract/cooperation-partner-contract-permissions';

export default {
  name: 'app-cooperation-partner-contract-list-toolbar',

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      hasRows: 'cooperationPartnerContract/list/hasRows',
      loading: 'cooperationPartnerContract/list/loading',
      selectedRows: 'cooperationPartnerContract/list/selectedRows',
    }),

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },

    hasPermissionToEdit() {
      return new CooperationPartnerContractPermissions(this.currentUser).edit;
    },
  },
};
</script>

<style>
</style>
